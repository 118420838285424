export default {
	data: () => ({
		ccFee: {
			id: 0,
			type: "",
			slug: "",
			fee_coefficient: "",
		},
		loading: false,
		backUrl: "/cc-fee"
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.ccFee = {
				id: 0,
				type: "",
				slug: "",
				fee_coefficient: "",
			};
		},
		validateCcFee() {
			this.ccFee.id > 0 ? this.update() : this.add();
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 10000);
		},
		update() {
			let _vm = this;
			// Send a PATCH request to update the ccFee
			this.axios
				.post("/ccs/" + this.ccFee.id, {
					...this.ccFee,
					_method: "PATCH"
				})
				.then(function (response) {
					// Update ccFee with the response data
					_vm.ccFee = response.data.data;
					_vm.$router.push(_vm.backUrl); // Redirect to the specified URL
				})
				.catch(function () {
					// Handle errors (optional)
				});
		},

		add() {
			let _vm = this;
			// Send a POST request to "/ccs" endpoint with _vm.ccFee data
			this.axios
				.post("/ccs", _vm.ccFee)
				.then(function () {
					// Redirect to _vm.backUrl after successful request
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () {
					// Handle any errors from the POST request
				});
		},

		getDetail() {
			let _vm = this;
			// Send a GET request to fetch data from the server
			this.axios
				.get("/ccs/" + this.$route.params.id)
				.then(function (response) {
					// Update the ccFee property with the received data
					_vm.ccFee = response.data.data;
				})
				.catch(function () {
					// Handle any errors that occurred during the request
				});
		}

	},
	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};

